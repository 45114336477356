<template>
  <div>
    <v-tooltip top v-if="values && values.length > 5">
      <template v-slot:activator="{ on, attrs }">
        <v-chip outlined small v-bind="attrs" v-on="on" color="grey">
          <v-icon small left>mdi-account</v-icon>
          {{ values.length }} Schüler:innen
        </v-chip>
      </template>
      <span>Entschuldigungen</span>
    </v-tooltip>
    <template v-else top>
      <JustificationChip
        v-for="(justification, index) in values"
        :value="justification"
        :key="'justification' + index"
      />
    </template>
  </div>
</template>
<script>
import JustificationChip from "common/components/Register/JustificationChip.vue";

export default {
  name: "JustificationChips",
  components: { JustificationChip },
  props: {
    values: {},
  },
};
</script>
