<template>
  <div v-if="value && value.length > 0">
    <RoomItem icon="mdi-map-marker" v-if="single" :value="value[0]"></RoomItem>
    <v-list-group v-else :value="false">
      <template v-slot:activator>
        <ListIcon><v-icon>mdi-map-marker-multiple</v-icon></ListIcon>
        <v-list-item-content>
          <v-list-item-title>{{ codes }}</v-list-item-title>
        </v-list-item-content>
      </template>
      <RoomItem v-for="item in value" :key="item.id" :value="item"></RoomItem>
    </v-list-group>
  </div>
</template>
<script>
import RoomItem from "common/components/RoomItem.vue";

export default {
  name: "RoomItems",
  props: ["value"],
  components: {
    RoomItem,
  },
  computed: {
    codes() {
      return this.value.map((item) => item.code).join(", ");
    },
    single() {
      return this.value && this.value.length === 1;
    },
  },
};
</script>
