<template>
  <div>
    <v-tooltip top v-if="values && values.length > 5">
      <template v-slot:activator="{ on, attrs }">
        <v-chip outlined small v-bind="attrs" v-on="on" color="grey">
          <v-icon small left>medical-bag</v-icon>
          {{ values.length }} Schüler:innen
        </v-chip>
      </template>
      <span>Absenzen</span>
    </v-tooltip>
    <template v-else top>
      <AbsenceChip
        v-for="(absences, index) in values"
        :value="absences"
        :key="'absences' + index"
      />
    </template>
  </div>
</template>
<script>
import AbsenceChip from "common/components/Register/AbsenceChip.vue";

export default {
  name: "AbsenceChips",
  components: { AbsenceChip },
  props: {
    values: {},
  },
};
</script>
