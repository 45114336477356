var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"overflow-x-auto",staticStyle:{"max-height":"100vh"},attrs:{"tile":""}},[_c('v-system-bar',{attrs:{"color":"info","window":""}},[_vm._v("Lektion "),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1)],1),_c('div',[_c('v-card-text',{staticClass:"title pt-8 pb-0 text-center"},[_vm._v(" "+_vm._s(_vm.value.course.title)+" ")]),_c('v-card-text',{staticClass:"subtitle pt-0 text-center"},[_c('DateValue',{attrs:{"value":_vm.day.date,"long":""}}),_vm._v(", "+_vm._s(_vm.durationText)+" ")],1),_c('v-card-text',{staticClass:"display text-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","color":_vm.color,"to":{
              name: 'CourseRegister',
              params: { course: _vm.value.course, id: _vm.value.course.id },
            }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(_vm._s(_vm.icon))])],1)]}}])},[_c('span',[_vm._v("zum Kursbuch")])])],1),(_vm.value.status && _vm.value.status.code != 'asPlanned')?_c('v-alert',{staticClass:"mb-0",attrs:{"text":"","color":_vm.lessonStatusColor(_vm.value.status)}},[_c('strong',[_vm._v(_vm._s(_vm.value.status.description))]),(_vm.value.statusText)?_c('span',[_vm._v(": "+_vm._s(_vm.value.statusText))]):_vm._e()]):_vm._e(),(_vm.value.absence)?_c('v-alert',{staticClass:"mb-0",attrs:{"color":"danger","text":"","icon":"mdi-bed"}},[_vm._v(" Sie waren in dieser Lektion abwesend! ")]):_vm._e(),_c('v-list',{attrs:{"subheader":""}},[(_vm.value.notes && (_vm.value.notes.length > 0 || _vm.canAddNote))?[_c('v-subheader',[_vm._v("Notizen"),_c('v-spacer'),(_vm.canAddNote)?_c('NoteAddButton',{attrs:{"color":"primary","defaults":{
              course: _vm.value.course,
              date: _vm.day.date,
              period: _vm.value.period,
              startTime: _vm.value.startTime,
              endTime: _vm.value.endTime,
            },"x-small":"","fab":""},on:{"updated":function($event){return _vm.$emit('updated')}}}):_vm._e()],1),_vm._l((_vm.value.notes),function(note){return _c('NoteItem',{key:'note' + note.id,attrs:{"value":note},on:{"updated":function($event){return _vm.$emit('updated')}}})})]:_vm._e(),((_vm.showAbsences && _vm.value.absences) || _vm.canAddAbsences)?[_c('v-subheader',[_vm._v("Absenzen"),_c('v-spacer'),(_vm.canAddAbsences)?_c('v-btn',{attrs:{"fab":"","x-small":"","color":"white","to":{
              name: 'AttendanceCheck',
              params: {
                course: _vm.value.course.id,
                date: _vm.day.date,
                period: _vm.value.periodId,
              },
            }}},[_c('AttendanceCheckStatus',{attrs:{"value":_vm.value}})],1):_c('AttendanceCheckStatus',{attrs:{"value":_vm.value}})],1),(_vm.showAbsences && _vm.value.absences)?_c('v-chip-group',{staticClass:"ml-4",attrs:{"column":""}},_vm._l((_vm.value.absences),function(absence){return _c('AbsenceChip',{key:'absence' + absence.id,attrs:{"value":absence}})}),1):_vm._e()]:_vm._e(),(_vm.showAbsences && _vm.value.dispensations)?[_c('v-subheader',[_vm._v("Dispensationen")]),_c('v-chip-group',{staticClass:"ml-4",attrs:{"column":""}},_vm._l((_vm.value.dispensations),function(dispensation){return _c('DispensationChip',{key:'dispensation' + dispensation.id,attrs:{"value":dispensation}})}),1)]:_vm._e(),(_vm.showAbsences && _vm.value.eventAbsences)?[_c('v-subheader',[_vm._v("Teilnahme an Termin")]),_c('v-chip-group',{staticClass:"ml-4",attrs:{"column":""}},_vm._l((_vm.value.eventAbsences),function(eventAbsence,index){return _c('EventAbsenceChip',{key:'eventAbsence' + index,attrs:{"value":eventAbsence}})}),1)]:_vm._e(),(_vm.showAbsences && _vm.value.justifications)?[_c('v-subheader',[_vm._v("Entschuldigungen")]),_c('v-chip-group',{staticClass:"ml-4",attrs:{"column":""}},_vm._l((_vm.value.justifications),function(justifications,index){return _c('JustificationChip',{key:'justifications' + index,attrs:{"value":justifications}})}),1)]:_vm._e(),(
          _vm.value.rooms &&
          (_vm.value.rooms.length > 0 ||
            _vm.value.reservations.length > 0 ||
            _vm.canAddReservation)
        )?[_c('v-subheader',[_vm._v("Zimmer "),_c('v-spacer'),(_vm.canAddReservation)?_c('ReservationAddButton',{attrs:{"color":"primary","defaults":{
              course: _vm.value.course,
              date: _vm.day.date,
              startTime: _vm.value.startTime,
              endTime: _vm.value.endTime,
            },"x-small":"","fab":""},on:{"updated":function($event){return _vm.$emit('updated')}}}):_vm._e()],1),_c('RoomItems',{attrs:{"value":_vm.value.rooms}}),_c('ReservationItems',{attrs:{"value":_vm.value.reservations},on:{"updated":function($event){return _vm.$emit('updated')}}})]:_vm._e(),(_vm.value.course.teachers)?[_c('v-subheader',[_vm._v("Lehrkräfte")]),_vm._l((_vm.value.course.teachers),function(teacher){return _c('PersonItem',{key:'teacher' + teacher.id,attrs:{"value":teacher}})})]:_vm._e()],2),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"block":"","text":"","color":"primary"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Schliessen ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }