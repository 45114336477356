<template>
  <span v-if="value && value.length == 2">{{ formated }}</span>
  <span v-else>{{ placeholder }}</span>
</template>
<script>
import { formatDatespan } from "common/utils/date";
export default {
  name: "DateSpan",
  props: {
    value: { type: Array },
    placeholder: { type: String, default: "—" },
    long: { type: Boolean, default: false },
    full: { type: Boolean, default: false },
  },
  computed: {
    formated() {
      return formatDatespan(this.value[0], this.value[1], this.long, this.full);
    },
  },
};
</script>
