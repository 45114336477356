<template>
  <v-bottom-sheet v-model="dialog" width="500">
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltip }">
          <v-chip
            small
            v-on="{ ...tooltip, ...dialog }"
            v-bind="attrs"
            outlined
          >
            <Icon left small>mdi-map-marker-plus</Icon>
            {{ value.room.code }}
          </v-chip>
        </template>

        <span v-if="value.description"
          >Reservation: {{ value.description }}</span
        >
        <span v-else>{{ value.room.type.description }}</span>
      </v-tooltip>
    </template>
    <ReservationSheet :value="value" @close="dialog = false" />
  </v-bottom-sheet>
</template>
<script>
import ReservationSheet from "common/components/ReservationSheet.vue";
export default {
  name: "ReservationChip",
  components: { ReservationSheet },
  props: {
    value: {
      type: Object,
      default: () => ({
        id: 0,
        room: {
          title: "",
          description: "",
          url: "",
          type: { description: "" },
        },
      }),
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {},
};
</script>
