<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-chip outlined small v-bind="attrs" v-on="on">
        <v-icon class="mr-1" small :color="statusColor(value.status)">
          {{ categoryIcon(value.category) }}
        </v-icon>
        <PersonName :value="value.student" />
      </v-chip>
    </template>
    <span>{{ value.category.description }}</span>
  </v-tooltip>
</template>
<script>
import {
  justificationCategoryIcon,
  justificationStatusColor,
  justificationStatusIcon,
} from "common/utils/icons.js";
import PersonName from "common/components/PersonName.vue";
export default {
  name: "DispensationChip",
  components: { PersonName },
  props: {
    value: {},
  },
  methods: {
    statusColor: justificationStatusColor,
    statusIcon: justificationStatusIcon,
    categoryIcon: justificationCategoryIcon,
  },
};
</script>
