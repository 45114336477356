<template>
  <v-dialog
    v-model="dialog"
    width="auto"
    scrollable
    :fullscreen="$vuetify.breakpoint.xs"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-list-item
        :href="canEdit ? null : value.url"
        v-on="canEdit ? on : null"
        v-bind="attrs"
        v-if="value"
        target="_blank"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item-avatar v-bind="attrs" v-on="on"
              ><v-icon :color="color">{{ icon }}</v-icon></v-list-item-avatar
            >
          </template>
          <span
            >{{ value.type.description }}
            {{
              value.type.code == "exam" && value.plannedExam
                ? "(eingetragen)"
                : ""
            }}</span
          >
        </v-tooltip>

        <v-list-item-content>
          <v-list-item-title>
            {{ value.type.description }}: {{ value.title }}</v-list-item-title
          >
          <v-list-item-subtitle class="text-wrap" v-if="value.description">
            {{ value.description }}</v-list-item-subtitle
          >
        </v-list-item-content>
        <v-list-item-action v-if="value.url">
          <v-btn icon @click.stop :href="value.url" target="_blank">
            <v-icon>mdi-link</v-icon></v-btn
          >
        </v-list-item-action>
      </v-list-item>
    </template>
    <NoteForm
      :id="value.id"
      @updated="$emit('updated')"
      @close="dialog = false"
    />
  </v-dialog>
</template>
<script>
import { noteColor, noteIcon } from "common/utils/icons.js";

import NoteForm from "common/components/NoteForm.vue";

export default {
  name: "NoteItem",
  components: { NoteForm },
  props: {
    value: null,
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    canEdit() {
      return (
        this.value &&
        this.value.course.teachers.some((el) => this.$_isPerson(el))
      );
    },
    color() {
      return noteColor(this.value);
    },
    icon() {
      return noteIcon(this.value, true);
    },
  },
};
</script>
