<template>
  <v-bottom-sheet v-model="dialog" width="500" v-if="value">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item
        link
        v-bind="attrs"
        v-on="on"
        :class="value.current ? 'current' : 'not-current'"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <ListIcon class="mr-4" v-bind="attrs" v-on="on">
              <v-badge
                v-if="lessonStatusIcon(value.status)"
                :color="lessonStatusColor(value.status)"
                :icon="lessonStatusIcon(value.status)"
                overlap
                bottom
              >
                <v-icon :disabled="!value.future && !value.current">{{
                  icon
                }}</v-icon>
              </v-badge>
              <v-icon v-else :disabled="!value.future && !value.current">{{
                icon
              }}</v-icon>
            </ListIcon>
          </template>

          <span v-if="value.status">{{
            value.statusText
              ? value.status.description + ": " + value.statusText
              : value.status.description
          }}</span>
        </v-tooltip>

        <v-list-item-content>
          <v-row no-gutters class="align-center">
            <v-col cols="auto">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item-subtitle v-bind="attrs" v-on="on">
                    {{ durationText }}
                  </v-list-item-subtitle>
                </template>
                <span v-if="value.status">{{
                  value.statusText
                    ? value.status.description + ": " + value.statusText
                    : value.status.description
                }}</span></v-tooltip
              >

              <v-list-item-title :class="classes">
                <span class="course">{{
                  value.course ? value.course.title : "–"
                }}</span>
              </v-list-item-title>
            </v-col>
            <v-col cols="grow" class="grow">
              <v-chip-group class="ml-4" column>
                <RoomChip
                  v-for="room in value.rooms"
                  :key="'room' + room.id"
                  :value="room"
                  :color="lessonStatusColor(value.status)"
                ></RoomChip>
                <ReservationChip
                  v-for="reservation in value.reservations"
                  :key="'reservation' + reservation.id"
                  :value="reservation"
                  color="success"
                ></ReservationChip>
                <NoteChip
                  v-for="note in value.notes"
                  :key="'note' + note.id"
                  :value="note"
                  @updated="$emit('updated')"
                ></NoteChip>
                <template v-if="showAbsences">
                  <AbsenceChips :values="value.absences"></AbsenceChips>
                  <EventAbsenceChips
                    :values="value.eventAbsences"
                  ></EventAbsenceChips>
                  <DispensationChips
                    :values="value.dispensations"
                  ></DispensationChips>
                  <JustificationChips
                    :values="value.justifications"
                  ></JustificationChips>
                </template>
              </v-chip-group>
            </v-col>
            <v-col class="text-right shrink">
              <AbsenceToggleButton
                small
                v-if="showAbsenceToggleButton"
                :value="value"
                :register="register"
                :day="day"
                @updated="$emit('updated')"
              />
            </v-col>
          </v-row>
        </v-list-item-content>

        <v-list-item-action v-if="!$_hasRole('student') && showAbsences">
          <AttendanceCheckStatus :value="value"></AttendanceCheckStatus>
        </v-list-item-action>
      </v-list-item>
    </template>
    <LessonSheet
      :active="active"
      :day="day"
      :register="register"
      :showAbsences="showAbsences"
      :value="value"
      @close="dialog = false"
      @updated="$emit('updated')"
    ></LessonSheet>
  </v-bottom-sheet>
</template>
<script>
import AbsenceChips from "common/components/Register/AbsenceChips.vue";
import AbsenceToggleButton from "common/components/Register/AbsenceToggleButton.vue";
import AttendanceCheckStatus from "common/components/Register/AttendanceCheckStatus.vue";
import DispensationChips from "common/components/Register/DispensationChips.vue";
import EventAbsenceChips from "common/components/Register/EventAbsenceChips.vue";
import JustificationChips from "common/components/Register/JustificationChips.vue";
import LessonSheet from "./LessonSheet.vue";
import NoteChip from "common/components/NoteChip.vue";
import RoomChip from "common/components/RoomChip.vue";
import ReservationChip from "common/components/ReservationChip.vue";

import {
  lessonStatusColor,
  lessonStatusIcon,
  subjectIcon,
} from "common/utils/icons.js";
import { formatTime } from "common/utils/date.js";

export default {
  name: "LessonItem",
  components: {
    AbsenceChips,
    AbsenceToggleButton,
    AttendanceCheckStatus,
    DispensationChips,
    EventAbsenceChips,
    JustificationChips,
    LessonSheet,
    NoteChip,
    RoomChip,
    ReservationChip,
  },
  props: {
    active: {
      type: Boolean,
      default: () => false,
    },
    showAbsences: { type: Boolean, default: false },
    showAbsenceToggleButton: { type: Boolean, default: false },
    value: null,
    day: null,
    register: {},
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    canAddNote() {
      return (
        this.value &&
        this.value.course &&
        this.value.course.teachers.some((el) => this.$_isPerson(el))
      );
    },
    classes() {
      if (
        this.value &&
        this.value.status &&
        (this.value.status.code == "canceledByEvent" ||
          this.value.status.code == "canceled")
      ) {
        return "canceled";
      }
      return "";
    },
    color() {
      if (this.value.past) {
        return "grey";
      }
      return this.value.change ? "danger" : "info";
    },
    icon() {
      if (!this.value || !this.value.course) {
        return "";
      }
      return subjectIcon(this.value.course.subject);
    },

    durationText() {
      return this.value
        ? formatTime(this.value.startTime) +
            " – " +
            formatTime(this.value.endTime)
        : "";
    },
  },
  methods: {
    lessonStatusColor,
    lessonStatusIcon,
  },
};
</script>

<style scoped>
.current {
  border-left: 2px solid #ea4335;
}
.not-current {
  border-left: 2px solid transparent;
}

.canceled .course {
  text-decoration-line: line-through;
}
</style>
