<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-chip outlined small v-bind="attrs" v-on="on" :color="color">
        <v-icon small left>{{ icon }}</v-icon>
        <PersonName :value="value.student" />
      </v-chip>
    </template>
    <span>Absenz: {{ toolTip }}</span>
  </v-tooltip>
</template>
<script>
import PersonName from "common/components/PersonName.vue";

import { absenceStatusColor, absenceIcon } from "common/utils/icons.js";

export default {
  name: "AbsenceChip",
  components: { PersonName },
  props: {
    value: {},
  },
  computed: {
    color() {
      return this.value ? absenceStatusColor(this.value) : "";
    },
    icon() {
      return absenceIcon(this.value);
    },
    toolTip() {
      if (!this.value || !this.value.status.code) return "";
      switch (this.value.status.code) {
        case "pending":
          return "Begründung pendent";
        case "unexcused":
          return "unentschuldigt";
        default:
          return this.value.justificationCategory.description;
      }
    },
  },
};
</script>
