<template>
  <v-bottom-sheet v-model="dialog" width="500" v-if="value">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item
        link
        :class="value.current ? 'current' : 'not-current'"
        v-bind="attrs"
        v-on="on"
      >
        <ListIcon>
          <v-icon color="warning">{{ icon }}</v-icon>
        </ListIcon>
        <v-list-item-content>
          <v-list-item-title>{{ name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <HolidaySheet :value="value" @close="dialog = false"></HolidaySheet>
  </v-bottom-sheet>
</template>
<script>
import { holidayIcon } from "common/utils/icons";
import HolidaySheet from "common/components/HolidaySheet.vue";

export default {
  name: "HolidayItem",
  components: { HolidaySheet },
  data() {
    return {
      dialog: false,
    };
  },
  props: {
    value: null,
  },
  computed: {
    name() {
      if (!this.value) {
        return "";
      }
      return this.value.name;
    },
    icon() {
      return holidayIcon(this.value);
    },
  },
};
</script>

<style scoped>
.current {
  border-left: 2px solid red;
}
</style>
