<template>
  <div v-if="value && value.attendanceCheckStatus">
    <template>
      <v-tooltip v-if="value.attendanceCheckStatus.code == 'signed'" top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" color="success"
            >mdi-checkbox-marked-outline</v-icon
          >
        </template>
        <span
          >Kontrolle durchgeführt von
          <PersonName :value="value.signedBy"></PersonName
        ></span>
      </v-tooltip>
      <v-tooltip v-else-if="value.attendanceCheckStatus.code == 'skipped'" top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" color="success"
            >mdi-checkbox-blank-off-outline</v-icon
          >
        </template>
        <span
          >Kontrolle nicht durchführbar (<PersonName
            :value="value.signedBy"
          />)</span
        >
      </v-tooltip>
      <v-tooltip v-else-if="value.attendanceCheckStatus.code == 'pending'" top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" color="error"
            >mdi-checkbox-blank-outline</v-icon
          >
        </template>
        <span>Kontrolle pendent</span>
      </v-tooltip>
      <v-tooltip v-else-if="value.attendanceCheckStatus.code == 'missed'" top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" color="error"
            >mdi-checkbox-blank-off-outline</v-icon
          >
        </template>
        <span>Kontrolle verpasst</span>
      </v-tooltip>
    </template>
  </div>
</template>

<script>
import PersonName from "common/components/PersonName.vue";

export default {
  props: ["value"],
  components: { PersonName },
};
</script>
