<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-chip outlined small v-bind="attrs" v-on="on" color="grey">
        <v-icon small left>mdi-calendar</v-icon>
        <PersonName :value="value.student" />
      </v-chip>
    </template>
    <span
      >Teilnahme an:<br /><strong>{{ value.event.description }}</strong></span
    >
  </v-tooltip>
</template>
<script>
import PersonName from "common/components/PersonName.vue";

export default {
  name: "EventAbsenceChip",
  components: { PersonName },
  props: {
    value: {},
  },
};
</script>
