<template>
  <v-dialog
    v-model="dialog"
    width="500"
    scrollable
    :fullscreen="$vuetify.breakpoint.xs"
  >
    <template v-slot:activator="{ on, attrs }" v-if="add">
      <v-btn small icon v-on="on" v-bind="attrs"
        ><v-icon>mdi-plus</v-icon></v-btn
      >
    </template>
    <template v-slot:activator="{ on: dialog, attrs }" v-else>
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltip }">
          <v-chip
            small
            v-on="canEdit ? { ...tooltip, ...dialog } : tooltip"
            v-bind="attrs"
            :color="color"
            outlined
            label
            :href="canEdit ? '' : value.url"
            target="_blank"
          >
            {{ value.title }}
            <v-icon v-if="value.url" right small>mdi-link-variant</v-icon>
          </v-chip>
        </template>

        <span v-if="value.description"
          >{{ value.type.description }}: {{ value.description }}</span
        >
        <span v-else>{{ value.type.description }}</span>
      </v-tooltip>
    </template>
    <NoteForm
      :id="value.id"
      @updated="$emit('updated')"
      @close="dialog = false"
    />
  </v-dialog>
</template>
<script>
import { noteColor } from "common/utils/icons.js";

import NoteForm from "common/components/NoteForm.vue";

export default {
  name: "NoteChip",
  components: { NoteForm },
  props: {
    value: {
      type: Object,
      default: () => ({
        id: 0,
        title: "",
        description: "",
        url: "",
        type: { description: "" },
      }),
    },
    add: { type: Boolean, default: false },
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    canEdit() {
      return (
        this.value &&
        this.value.course &&
        this.value.course.teachers.some((el) => this.$_isPerson(el))
      );
    },
    color() {
      return noteColor(this.value);
    },
  },
};
</script>
