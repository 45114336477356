<template>
  <span v-bind="$attrs" v-on="{ ...$listeners }" v-if="value">{{
    formated
  }}</span>
  <span v-bind="$attrs" v-on="{ ...$listeners }" v-else>{{ placeholder }}</span>
</template>
<script>
import { formatDate } from "common/utils/date";
export default {
  name: "DateValue",
  props: {
    value: { type: String },
    placeholder: { type: String, default: "—" },
    long: { type: Boolean, default: false },
    full: { type: Boolean, default: false },
  },
  computed: {
    formated() {
      return formatDate(this.value, this.long, this.full);
    },
  },
};
</script>
