<template>
  <div>
    <v-tooltip top v-if="values && values.length > 5">
      <template v-slot:activator="{ on, attrs }">
        <v-chip outlined small v-bind="attrs" v-on="on" color="grey">
          <Icon small left>mdi-account-remove</Icon>
          {{ values.length }} Schüler:innen
        </v-chip>
      </template>
      <span>Dispensationen</span>
    </v-tooltip>
    <template v-else top>
      <DispensationChip
        v-for="(dispensation, index) in values"
        :value="dispensation"
        :key="'dispensation' + index"
      />
    </template>
  </div>
</template>
<script>
import DispensationChip from "common/components/Register/DispensationChip.vue";

export default {
  name: "DispensationChips",
  components: { DispensationChip },
  props: {
    values: {},
  },
};
</script>
